import { REPORT_ACTION } from './wdr.constant';

const CREATE_REPORT_STEPPER_TITLE = 'Create New Report';
const EDIT_REPORT_STEPPER_TITLE = 'Edit Report';
const getReportStepperTitle = (action: string) => (action === REPORT_ACTION.UPDATE ? EDIT_REPORT_STEPPER_TITLE : CREATE_REPORT_STEPPER_TITLE);

export const DYNAMIC_REPORTS_STEPPER_HEADER = (action: string) => ({
  reportDetails: {
    title: getReportStepperTitle(action),
    description: '',
  },
  reportCriteria: {
    title: getReportStepperTitle(action),
    description: 'Select report criteria',
  },
  reportColumns: {
    title: getReportStepperTitle(action),
    description: 'Select rows',
  },
  selectStackReport: {
    title: getReportStepperTitle(action),
    description: 'Select reports',
  },
});

export const SHARE_REPORT_DIALOG_HEADER = {
  title: 'Share Report',
  description: '',
};

const COMMON_REQ_PARAMS = {
  dataSource: 'datasource_id',
  hierarchyType: 'hierarchy_id',
  hierarchyLevel: 'level_id',
  company: 'company_id',
  timePeriod: 'time_period_id',
};

/** Report Criteria */
export const REPORT_CRITERIA = {
  FORM_CONTROL_NAMES: ['dataSource', 'hierarchyType', 'hierarchyLevel', 'company', 'timePeriod'],
  FORM_CONTROL_OPTIONS: ['dataSourceOptions', 'hierarchyTypeOptions', 'hierarchyLevelOptions', 'companyOptions', 'timePeriodOptions'],
  RELATIONSHIP_KEYS_CONFIG: {
    dataSource: [],
    hierarchyType: [],
    hierarchyLevel: [{ keyName: 'hierarchy_id', controlName: 'hierarchyType' }],
    company: [],
    timePeriod: [{ keyName: 'datasource_id', controlName: 'dataSource' }],
  },
  // Used in both report criteria and report column steps
  HIERARCHICAL_REQ_PARAMS: { ...COMMON_REQ_PARAMS },
};

export const LEVEL_CONFIG = {
  10: {
    valueObjRef: 'dealer_code',
    displayObjRef: 'dealer_name',
    customDisplayObj: 'dealer_name',
    displayMultipleObj: ['region_code','district_code','dealer_code','dealer_name'],
    panelClass: 'dxfw-wdr-dealer-select-panel',
    tableConfig: [
      { header: 'Rg. code', valueRef: 'region_code', width: 50, textAlign: 'left' },
      { header: 'Dt. code', valueRef: 'district_code', width: 50, textAlign: 'left' },
      { header: 'Dl. code', valueRef: 'dealer_code', width: 60, textAlign: 'left' },
      { header: 'Dl. name', valueRef: 'dealer_name', width: 300, textAlign: 'left' },
    ]
  },
  11: {
    valueObjRef: 'district_code',
    displayObjRef: 'district_name',
    customDisplayObj: 'district_name',
    displayMultipleObj: ['region_code','district_code','district_name'],
    panelClass: 'dxfw-wdr-dealer-select-panel-small',
    tableConfig: []
  },
  12: {
    valueObjRef: 'region_code',
    displayObjRef: 'region_name',
    customDisplayObj: 'region_name',
    displayMultipleObj: ['region_code','region_name'],
    panelClass: 'dxfw-wdr-dealer-select-panel-small',
    tableConfig: []
  },
};

export const CONFIG_CONSTANTS = {
  valueObjRef: 'valueObjRef',
  displayObjRef: 'displayObjRef',
  customDisplayObj: 'customDisplayObj',
  displayMultipleObj: 'displayMultipleObj',
  panelClass: 'panelClass',
  tableConfig: 'tableConfig'
};
