export const tooltipTable = {
  header: ['HIERARCHY TYPE', 'HIERARCHY LEVEL'],
  data: [
    {
      type: 'TMS',
      level: ['Company', 'Region', 'District', 'Dealer'],
    },
    {
      type: 'TFS',
      level: ['National', 'Region', 'DSSO', 'Area', 'Dealer'],
    },
    {
      type: 'Dealer Group',
      level: ['Company', 'Entity', 'Dealer Group', 'Dealer'],
    },
    {
      type: 'GST',
      level: ['Region', 'Area', 'District', 'Dealer'],
    },
  ],
};
