import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs/dist/exceljs';
import * as FileSaver from 'file-saver';
import * as constants from '../../../constants/wdr.constant';
import { SpinnerService } from '../../../shared/services/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class ViewReportExportExcelService {
  constructor(private readonly spinnerService: SpinnerService) {}

  isHierarchicalReport(templateId: number): boolean {
    return templateId === constants.TEMPLATE_ID.HIERARCHICAL;
  }
  isComparisonReport(templateId: number): boolean {
    return templateId === constants.TEMPLATE_ID.COMPARISON;
  }
  isHistoricalReport(templateId: number): boolean {
    return templateId === constants.TEMPLATE_ID.HISTORICAL;
  }

  // Update Title Row Date Column
  updateTitleRowDateColumn(worksheet: ExcelJS.Worksheet, sheetRowIndex, cellValue: string): void {
    const titleRowDateColumnIndex = 4;
    const dateColumnCell = worksheet.getCell(sheetRowIndex, titleRowDateColumnIndex);
    dateColumnCell.value = cellValue;
    dateColumnCell.font = { name: 'Arial', size: 9, bold: true };
    dateColumnCell.alignment = { vertical: 'middle', horizontal: 'left' };
  }

  // Save Excel file
  saveExcel(workbook: ExcelJS.Workbook, fileName: string): void {
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, `${fileName}.xlsx`);
    });
  }

  // Sanitize date string to avoid invalid characters in file name
  sanitizeDate(date: string): string {
    return date.replace(/[\\/*?:[\]]/g, '_');
  }

  // Convert RGB color to Hex
  rgbToHex(rgb: string): string {
    const rgbValues = rgb.match(/\d+/g);
    if (!rgbValues || rgbValues.length !== 3) {
      throw new Error('Invalid RGB color format');
    }
    return rgbValues
      .map((value) => {
        const hex = parseInt(value).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('');
  }

  // Add Document Header
  addDocHeader(worksheet: ExcelJS.Worksheet, data): void {
    // Table Title & Date
    const titleRow = worksheet.addRow([data.titleText]);
    titleRow.font = { name: 'Arial', size: 9, bold: true };
    titleRow.alignment = { vertical: 'middle', horizontal: 'left' };
    worksheet.mergeCells('A1:C1');
    this.updateTitleRowDateColumn(worksheet, data.sheetRowIndex, `DATE: (${data.date.toString().toUpperCase()})`);
    // Table Blank Row
    worksheet.getRow(2).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } }; // Matching the #F0D9D2 color
    // Table Caption Row
    const tableCaptionRow = worksheet.addRow([data.tableCaption]);
    tableCaptionRow.alignment = { vertical: 'middle', horizontal: 'center' };
    if (this.isHistoricalReport(data.template_id)) {
      worksheet.mergeCells('A3:N3');
    } else {
      worksheet.mergeCells('A3:D3');
    }
  }

  isWithinMergedCell(excelParamsObj, cell, cellIndex) {
    return excelParamsObj.sheetRowIndex > cell.rowIndex && excelParamsObj.sheetRowIndex < cell.rowIndex + cell.rowSpan && cell.colIndex === cellIndex;
  }

  getStyleConfig() {
    const fontStyle: Partial<ExcelJS.Font> = { name: 'Arial', bold: false, size: 9, color: { argb: '262626' } };
    const headerBorderStyle: Partial<ExcelJS.Border> = { style: 'medium', color: { argb: '9A9064' } };
    const headerFillStyle: ExcelJS.Fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C7C1A9' } };
    const cellBorderStyle: Partial<ExcelJS.Border> = { style: 'medium', color: { argb: 'E5EBEB' } };
    const bodyFillStyle: ExcelJS.Fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F0F0F0' } };
    return { fontStyle, headerBorderStyle, headerFillStyle, cellBorderStyle, bodyFillStyle };
  }

  applyStylesToBodySectionCells(excelCell: ExcelJS.Cell, row, tableRowIndex, excelParamsObj) {
    const { bodyFillStyle } = this.getStyleConfig();
    if ((tableRowIndex + 1) % 2 === 0) {
      excelCell.fill = bodyFillStyle; // Even Row Color
    }
    // Report Specific Styles
    if (this.isHierarchicalReport(excelParamsObj.template_id)) {
      excelCell.alignment = { vertical: 'middle', horizontal: excelParamsObj.cellIndex < 4 ? 'left' : 'right' };
      const backgroundColor = (row as HTMLElement).style.backgroundColor;
      if (backgroundColor) {
        excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: this.rgbToHex(backgroundColor) } } as ExcelJS.Fill;
      }
    } else if (this.isHistoricalReport(excelParamsObj.template_id)) {
      const indexNotEqualTo3 = excelParamsObj.cellIndex === 3 ? 'center' : 'right';
      excelCell.alignment = { vertical: 'middle', horizontal: excelParamsObj.cellIndex === 2 ? 'left' : indexNotEqualTo3 };
    } else if (this.isComparisonReport(excelParamsObj.template_id)) {
      excelCell.alignment = { vertical: 'middle', horizontal: excelParamsObj.cellIndex === 2 ? 'left' : 'right' };
    }
  }

  processHeaderAndBodyStyles(cell, row, excelCell, tableRowIndex, excelParamsObj) {
    const { fontStyle, headerBorderStyle, headerFillStyle } = this.getStyleConfig();
    if (excelParamsObj.isHeader) {
      if (cell.classList.contains('dxfw-wdr-view-report-header-cell')) {
        excelCell.font = { ...fontStyle, bold: true };
        excelCell.fill = headerFillStyle;
        excelCell.border = { top: headerBorderStyle, left: headerBorderStyle, bottom: headerBorderStyle, right: headerBorderStyle };
        excelCell.alignment = { ...excelCell.alignment, horizontal: 'center' };
      }
    } else {
      this.applyStylesToBodySectionCells(excelCell, row, tableRowIndex, excelParamsObj);
    }
  }

  processTableCells(cell: HTMLTableCellElement, row, excelCurrentRow, mergedCells, tableRowIndex, excelParamsObj): void {
    const { fontStyle, cellBorderStyle } = this.getStyleConfig();
    const rowspan = parseInt(cell.getAttribute('rowspan') || '1', 10);
    const colspan = parseInt(cell.getAttribute('colspan') || '1', 10);
    const excelCell: ExcelJS.Cell = excelCurrentRow.getCell(excelParamsObj.cellIndex);

    excelCell.value = cell.innerText || '';
    excelCell.border = { top: cellBorderStyle, left: cellBorderStyle, bottom: cellBorderStyle, right: cellBorderStyle };
    excelCell.font = fontStyle;

    const textLength = excelCell.value ? excelCell.value.toString().length : 10;
    excelParamsObj.columnWidths[excelParamsObj.cellIndex - 1] = Math.max(excelParamsObj.columnWidths[excelParamsObj.cellIndex - 1] || 0, textLength);

    if (rowspan > 1) {
      excelParamsObj.worksheet.mergeCells(
        excelParamsObj.sheetRowIndex,
        excelParamsObj.cellIndex,
        excelParamsObj.sheetRowIndex + rowspan - 1,
        excelParamsObj.cellIndex
      );
      mergedCells.push({ rowIndex: excelParamsObj.sheetRowIndex, colIndex: excelParamsObj.cellIndex, rowSpan: rowspan });
    }

    if (colspan > 1) {
      excelParamsObj.worksheet.mergeCells(
        excelParamsObj.sheetRowIndex,
        excelParamsObj.cellIndex,
        excelParamsObj.sheetRowIndex,
        excelParamsObj.cellIndex + colspan - 1
      );
      excelParamsObj.cellIndex += colspan - 1; // Skip the columns covered by colspan
    } else {
      excelParamsObj.cellIndex++;
    }
    // Process table header and body styles
    this.processHeaderAndBodyStyles(cell, row, excelCell, tableRowIndex, excelParamsObj);
  }

  processTableRows(excelParamsObj): number {
    const mergedCells: { rowIndex: number; colIndex: number; rowSpan: number }[] = []; // To Track merged columns in each row

    excelParamsObj.tableRows.forEach((row: HTMLTableRowElement, tableRowIndex) => {
      const cells = row.querySelectorAll(excelParamsObj.isHeader ? 'th' : 'td');
      const excelCurrentRow = excelParamsObj.worksheet.getRow(excelParamsObj.sheetRowIndex);
      excelParamsObj.cellIndex = 1;

      if (mergedCells.some((cell) => this.isWithinMergedCell(excelParamsObj, cell, excelParamsObj.cellIndex))) {
        excelParamsObj.cellIndex++; // Skip the columns covered by rowspan
      }

      cells.forEach((cell: HTMLTableCellElement) => {
        this.processTableCells(cell, row, excelCurrentRow, mergedCells, tableRowIndex, excelParamsObj);
      });
      excelParamsObj.sheetRowIndex++;
    });
    return excelParamsObj.sheetRowIndex;
  }

  exportToExcel(reportData): void {
    this.spinnerService.displaySpinner();
    const { title, date, tableCaption, template_id } = reportData;
    const titleText = title?.toUpperCase();
    const formattedDate = this.sanitizeDate(date.toString());
    const sheetName = `${titleText}_${formattedDate}`;
    const fileName = `${sheetName}_${Date.now()}`;
    const workbook = new ExcelJS.Workbook(); // Create a workbook
    const worksheet: ExcelJS.Worksheet = workbook.addWorksheet(`${sheetName}`); // Create a worksheet
    const tables = document.querySelectorAll('table'); // Get all tables from the DOM

    const columnWidths: number[] = [];
    let sheetRowIndex = 1; // Worksheet Start from Row 1

    this.addDocHeader(worksheet, { titleText, sheetRowIndex, date, tableCaption, template_id }); // Add Document Header
    sheetRowIndex = 4; // Table Start from Row 4

    tables.forEach((table: HTMLTableElement) => {
      const processRows = (tableRows, isHeader) => {
        sheetRowIndex = this.processTableRows({ worksheet, tableRows, isHeader, sheetRowIndex, columnWidths, template_id });
      };
      processRows(table.querySelectorAll('thead tr'), true); // Process header rows
      processRows(table.querySelectorAll('tbody tr'), false); // Process body rows
      sheetRowIndex++;
    });

    columnWidths.forEach((width, index) => (worksheet.getColumn(index + 1).width = width + 2)); // Updating cell width

    this.saveExcel(workbook, fileName);
    this.spinnerService.hideSpinner();
  }
}
