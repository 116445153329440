import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomTooltipComponent {
  @Input() showTooltip = true;
  @Input() topPosition = 0;
  @Input() leftPosition = 0;
  @Input() tooltipData: any;
  @Input() isTooltipTable = true;
}
