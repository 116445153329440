import { Component } from '@angular/core';
import { ViewReportExportPdfService } from '../../services/view-report-export-pdf.service';
import { ViewReportExportExcelService } from '../../services/view-report-export-excel.service';
import { ViewReportService } from '../../services/view-report.service';
import { IMyReports } from '../../../../modules/my-reports/interface/my-reports.interface';
import * as constants from '../../../../constants/wdr.constant';
import { DynamicReportsUtilityService } from '../../../../shared/services/dynamic-reports-utility.service';
import { LEVEL_CONFIG } from '../../../../constants/build-reports-stepper.constant';
import { IGetReportTableResponse } from '../../interface/view-report.interface';

@Component({
  selector: 'app-report-content',
  templateUrl: './report-content.component.html',
  styleUrls: ['./report-content.component.scss'],
})
export class ReportContentComponent {
  tableHeader = [];
  viewReportDetails: IMyReports;
  viewFilteredData;
  reportObject: any = {};
  constant = constants;
  viewReportTableData: IGetReportTableResponse[];
  spanCount = 1;

  constructor(
    public viewReportService: ViewReportService,
    private readonly exportPdfService: ViewReportExportPdfService,
    private readonly exportExcelService: ViewReportExportExcelService,
    private readonly dynamicReportsUtilityService: DynamicReportsUtilityService
  ) {}

  ngOnInit() {
    this.viewReportTableData = this.viewReportService.viewReportTableData;
    this.viewReportDetails = this.viewReportService.viewReportDetails;
    this.viewFilteredData = this.viewReportService.viewFilteredData;
    this.setReportTableConfig();
    this.reportObject = {
      title: this.viewReportDetails?.report_title,
      date: this.viewFilteredData?.date?.value,
      tableCaption: this.joinSelectedItem(),
      template_id: this.viewReportDetails?.template_id,
    };
  }

  setReportTableConfig() {
    this.viewReportTableData?.forEach((tConfig) => {
      if (!this.tableHeader.length) {
        tConfig.rows.forEach((rowData) => {
          this.tableHeader.push({ columnName: rowData?.column_name, format: rowData?.format });
        });
      }
    });
  }

  rowSpan(rowIndex, colName, colIndex) {
    let i = 1;
    this.spanCount = 1;
    while (colIndex === 0 && i !== 0) {
      if (colName?.value === this.viewReportTableData?.[rowIndex + i]?.rows[0]?.value) {
        this.spanCount++;
        i++;
      } else {
        i = 0;
      }
    }
    return this.spanCount;
  }

  skipSpannedColumn(rowIndex, colName, colIndex) {
    return !(colIndex === 0 && colName?.value === this.viewReportTableData?.[rowIndex - 1]?.rows[0]?.value);
  }

  isHierarchicalReport() {
    return this.viewReportDetails?.template_id === constants.TEMPLATE_ID.HIERARCHICAL;
  }

  isComparisonReport() {
    return this.viewReportDetails?.template_id === constants.TEMPLATE_ID.COMPARISON;
  }

  isHistoricalReport() {
    return this.viewReportDetails?.template_id === constants.TEMPLATE_ID.HISTORICAL;
  }

  joinSelectedItem() {
    const propertiesToJoin = LEVEL_CONFIG?.[this.viewReportService.viewReportDetails?.level_id]?.displayMultipleObj;
    let joinedString = '';
    propertiesToJoin?.forEach((element: any) => {
      joinedString += this.viewFilteredData?.item?.selectedOptions[0][element] + '-';
    });
    joinedString = joinedString.substring(0, joinedString.length - 1);
    return joinedString;
  }

  getExportDocumentInfo() {
    let monthInString = '',
      year = '',
      dateParts = [];
    if (this.reportObject?.date?.toString().includes('/')) {
      dateParts = this.reportObject?.date?.split('/') || [];
    }

    if (dateParts?.length === 2) {
      [monthInString, year] = dateParts;
    } else if (dateParts?.length === 3) {
      [monthInString, , year] = dateParts;
    } else {
      year = this.reportObject?.date || '';
    }
    monthInString = this.dynamicReportsUtilityService.getMonthName(monthInString) || '';
    return { ...this.reportObject, date: `${monthInString} ${year}` };
  }

  exportToExcel() {
    const reportDataForExcel = { ...this.reportObject };
    this.exportExcelService.exportToExcel(reportDataForExcel);
  }

  exportToPdf() {
    const reportDataForPDF = { ...this.reportObject };
    this.exportPdfService.exportToPdf(reportDataForPDF);
  }
}
