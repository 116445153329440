<ng-container *ngIf="showTooltip">
  <div
    class="tooltip dxfw-wdr-tooltip-container"
    [style.top]="topPosition + 'px'"
    [style.left]="leftPosition + 'px'"
    [ngClass]="isTooltipTable ? 'tooltip-table-container' : ''">
    <div *ngIf="isTooltipTable; else tooltipContent">
      <table class="tooltip-table" *ngIf="tooltipData">
        <thead>
          <tr>
            <th *ngFor="let header of tooltipData?.header">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of tooltipData?.data">
            <td>
              {{ data?.type }}
            </td>
            <td class="width-60">
              <ng-container *ngFor="let level of data?.level">
                <ul>
                  <li>{{ level }}</li>
                </ul>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ng-template #tooltipContent> {{ tooltipData }} </ng-template>
  </div>
</ng-container>
