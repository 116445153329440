import { ChangeDetectorRef, Component, ComponentRef, OnInit } from '@angular/core';
import { IUserProfile, UserProfileService } from '@toyota/dd365-platform-library';
import { SpinnerService } from './shared/services/spinner.service';
import { Subscription, Observable, map } from 'rxjs';
import { singleSpaPropsSubject, SingleSpaProps } from '../single-spa/single-spa-props';
import { WDR_CONSTANT } from './constants/wdr.constant';
import { DynamicReportsDomService } from './shared/services/dynamic-reports-dom.service';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private singleSpaPropsSubscription: Subscription | undefined;
  public subscriptions: Subscription = new Subscription();
  public userProfileSubscription: Subscription | undefined;
  public profile: IUserProfile | undefined;
  public singleSpaProps: any;
  public spinnerComRef: ComponentRef<any>;

  constructor(
    public userProfileService: UserProfileService,
    public changeDetector: ChangeDetectorRef,
    public readonly spinnerService: SpinnerService,
    public readonly dynamicReportsDomService: DynamicReportsDomService
  ) {}

  ngOnInit() {
    this.initTimer();
    this.subscriptions.add(this.getSpinnerSubscription().subscribe());
    this.spinnerComRef = this.dynamicReportsDomService.loadDynamicComponent(SpinnerComponent);
    // Single Spa Props subscription will only get triggered when the app bootstraps
    this.singleSpaPropsSubscription = singleSpaPropsSubject.subscribe((props: SingleSpaProps) => {
      this.singleSpaProps = props;
      // User profile subscription will get triggered any time the DD365 platform broadcasts a user profile update
      this.userProfileSubscription = this.singleSpaProps['userProfile'].subscribe((profile: IUserProfile) => {
        this.userProfileService.updateProfile(profile);
        this.changeDetector.detectChanges();
      });
      // Add subscription to common component subscription to facilitate easy subscription management
      this.subscriptions.add(this.userProfileSubscription);
    });
    // Add subscription to common component subscription to facilitate easy subscription management
    this.subscriptions.add(this.singleSpaPropsSubscription);
  }

  public initTimer() {
    const setUpTime = localStorage.getItem('forgerock-session');
    const defaultTime = WDR_CONSTANT.forgeRockSessionTime;
    if (setUpTime !== null) {
      const currentTime = Date.now();
      if (Number(currentTime) - Number(setUpTime) > defaultTime) {
        localStorage.removeItem('forgerock-session');
      }
    }
  }

  public getSpinnerSubscription(): Observable<void> {
    return this.spinnerService.getSpinner$().pipe(
      map((showSpinner: boolean) => {
        const instance = this.spinnerComRef.instance;
        setTimeout(() => {
          instance['showSpinner'] = showSpinner;
        }, 0);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.dynamicReportsDomService.removeDynamicComponent(this.spinnerComRef);
    localStorage.removeItem('selected-obj');
    localStorage.removeItem('userIp');
  }
}
