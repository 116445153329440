import { Injectable } from '@angular/core';
import { jsPDF, jsPDFOptions } from 'jspdf';
import autoTable, { CellHookData, Color, Styles, UserOptions } from 'jspdf-autotable';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { TEMPLATE_ID } from '../../../constants/wdr.constant';

@Injectable({
  providedIn: 'root',
})
export class ViewReportExportPdfService {
  constructor(private readonly spinnerService: SpinnerService) {}

  getDefaultPDFOptions(customPDFOptions = {}): jsPDFOptions {
    const defaultPDFOptions: jsPDFOptions = {
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    };
    return { ...defaultPDFOptions, ...customPDFOptions };
  }

  setFontStyle(doc: jsPDF): void {
    doc.setFont('helvetica', 'normal', 'bold');
    doc.setFontSize(7);
  }

  setDocumentHeader(doc: jsPDF, header): void {
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.text(header.title.toUpperCase(), 10, 15, { align: 'left' });
    doc.text(`DATE: (${header.date.toString().toUpperCase()})`, pageWidth - 20, 15, { align: 'right' });
    doc.text(header.tableCaption, pageWidth / 2, 25, { align: 'center' });
  }

  configTableBodySectionByTemplateId(data: CellHookData, _table: HTMLTableElement, reportData): void {
    const columnIndex = data.column.index;
    const rowIndex = data.row.index;
    data.cell.styles.valign = 'middle';
    this.setCellAlignment(data, reportData, columnIndex);
    if (data.section === 'body') {
      this.setBodySectionStyles(data, rowIndex);
      if (reportData.template_id === TEMPLATE_ID.HIERARCHICAL) {
        this.setHierarchicalReportStyles(data, _table, columnIndex, rowIndex);
      }
    }
  }

  setCellAlignment(data: CellHookData, reportData, columnIndex: number): void {
    if (reportData.template_id === TEMPLATE_ID.HIERARCHICAL) {
      data.cell.styles.halign = columnIndex < 2 ? 'left' : 'right'; // Align the first two cells to the left
    } else if (reportData.template_id === TEMPLATE_ID.COMPARISON) {
      data.cell.styles.halign = columnIndex < 1 ? 'left' : 'right';
      if (data.section === 'head') {
        data.cell.styles.halign = 'center';
      }
    }
  }

  setBodySectionStyles(data: CellHookData, rowIndex: number): void {
    if ((rowIndex + 1) % 2 === 0) {
      data.cell.styles.fillColor = [240, 240, 240]; // set color for even rows
    }
  }

  setHierarchicalReportStyles(data: CellHookData, _table: HTMLTableElement, columnIndex: number, rowIndex: number): void {
    const rowElement = _table.querySelector(`tbody tr:nth-child(${rowIndex + 1})`);
    if (rowElement) {
      const backgroundColor = (rowElement as HTMLElement).style.backgroundColor;
      if (backgroundColor) {
        data.cell.styles.fillColor = backgroundColor as Color;
      }
    }
    if (columnIndex === 1) {
      data.cell.styles.cellWidth = 55;
    }
  }

  getAutoTableUserOptions(_table: HTMLTableElement, reportData): UserOptions {
    const endYCoord = 27;
    const tableLineColor: Color = [154, 144, 100];
    const tableCellLineColor: Color = [229, 235, 235];
    const commonStyles: Partial<Styles> = {
      halign: 'center',
      textColor: [51, 51, 51],
      lineColor: tableLineColor,
      lineWidth: 0.1,
    };
    const headStyles: Partial<Styles> = {
      fillColor: [199, 193, 169],
      lineColor: tableLineColor,
      lineWidth: 0.01,
      minCellHeight: 6,
      fontSize: 6,
    };
    const bodyStyles: Partial<Styles> = {
      minCellHeight: 6, // Increase row height
      fontSize: 5,
      lineColor: tableCellLineColor,
    };
    const customTableStyleOptions: UserOptions = {
      html: '#dxfw-wdr-view-report-table', // table id
      theme: 'grid',
      startY: endYCoord,
      tableLineWidth: 0.1,
      styles: commonStyles,
      headStyles: headStyles,
      bodyStyles: bodyStyles,
      columnStyles: {
        0: { halign: 'left' },
      },
      pageBreak: 'avoid',
      margin: { left: 10 },
      didParseCell: (data: CellHookData) => {
        this.configTableBodySectionByTemplateId(data, _table, reportData);
      },
      didDrawCell: (data: CellHookData) => {
        data.cell.styles.valign = 'middle';
      },
    };
    return customTableStyleOptions;
  }

  printTables(doc: jsPDF, reportData): void {
    const tables = document.querySelectorAll('table'); // select tables
    tables.forEach((_table: HTMLTableElement, _index: number) => {
      const autoTableUserOptions = this.getAutoTableUserOptions(_table, reportData);
      autoTable(doc, autoTableUserOptions);
    });
  }

  savePdf(doc: jsPDF, fileName: string): void {
    doc.save(`${fileName}.pdf`);
    const pdfBlob = doc.output('blob');
    const url = URL.createObjectURL(pdfBlob);
    window.open(url, '_blank');
  }

  async exportToPdf(reportData): Promise<void> {
    this.spinnerService.displaySpinner();
    const pdfOptions = this.getDefaultPDFOptions();
    const fileName = `${reportData.title}_${reportData.date}_${Date.now()}`;
    const doc = new jsPDF(pdfOptions);
    this.setFontStyle(doc);
    this.setDocumentHeader(doc, reportData);
    this.printTables(doc, reportData);
    this.savePdf(doc, fileName);
    this.spinnerService.hideSpinner();
  }
}
